import React, { useState } from "react";
import { toast } from "react-toastify";
import { useGetRecieverNameQuery, useSetPaymentTransferMutation } from "../../../redux/walletApi";
import { availableEnum } from "../../constant/enum";
import { InputValid } from "../../validations/InputValid";
import { SelectValid } from "../../validations/SelectValid";

function Transfer() {
    const [setPaymentTransfer] =useSetPaymentTransferMutation()
  const [recieverName, setRecieverName] = useState("");
  const [recieverNameErr, setRecieverNameErr] = useState("");
  const [quantity, setQuantity] = useState("");
  const [quantityErr, setQuantityErr] = useState("");
  const [available, setAvailable] = useState(1);
  const { data } = useGetRecieverNameQuery();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "recieverName") {
      setRecieverName(value);
      const err = SelectValid(name, value);
      setRecieverNameErr(err);
    }
    if (name === "available") {
      setAvailable(value);
    }
    if (name === "quantity") {
      setQuantity(value);
      const err = InputValid(name, value);
      setQuantityErr(err);
    }
  };

  const onSubmit=(e)=>{
    e.preventDefault()
    const checkQuantity = InputValid("quantity", quantity);
    if(checkQuantity){
        setQuantityErr(checkQuantity);
        return false
    }
    const checkReciever = SelectValid("recieverName", recieverName);
    if(checkReciever){
        setRecieverNameErr(checkReciever);
        return false
    }
    const data={
        A_nToEntityId:parseInt(recieverName),
        A_nUnderlyingTokenNo:available,
        A_nAmount:parseFloat(quantity),
    }
    setPaymentTransfer(data).then((result) => {
        if (result.data.status) {
          toast.dismiss();
          toast.success(result.data.message);
        }
      });
  }

  return (
    <div>
      <div className="w_box_border  p-3">
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label>Available USDT/GZUT</label>
            <select className="form-control" value={available} name="available" onChange={handleChange}>
              {availableEnum?.map((list) => {
                return (
                  <option value={list.value} key={list.key}>
                    {list.key}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-group">
            <label>Quantity</label>
            <input
              className="form-control"
              type="number"
              placeholder="Enter Quantity"
              value={quantity}
              name="quantity"
              min="0"
              onChange={handleChange}
            />
            <span className="text-danger">{quantityErr}</span>
          </div>
          <div className="form-group">
            <label> Receiver Name</label>
            <select
              className="form-control"
              value={recieverName}
              name="recieverName"
              onChange={handleChange}
            >
              <option value="">Select reciever name</option>
              {data?.map((list) => {
                return (
                  <option
                    value={list.nReferredEntityId}
                    key={list.nReferredEntityId}
                  >
                    {list.sReferredEntityName}
                  </option>
                );
              })}
            </select>
            <span className="text-danger">{recieverNameErr}</span>
          </div>
          <button type="submit" className="btn">
            Confirm
          </button>
        </form>
      </div>
    </div>
  );
}

export default Transfer;
