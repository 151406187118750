import { getMyBalanceApi, getRecieverNameApi, getWalletTransactionApi, paymentTransferApi } from "../components/constant/Api";
import { myApi } from "./api";

export const walletApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getWalletTransaction: builder.mutation({
      query: (post) => ({
        url: getWalletTransactionApi,
        method: "POST",
        body:post
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
    }),
    getRecieverName: builder.query({
      query: (post) => ({
        url: getRecieverNameApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
    }),
    getMyBalance: builder.query({
      query: (post) => ({
        url: getMyBalanceApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" :"";
      },
    }),
    setPaymentTransfer: builder.mutation({
      query: (post) => ({
        url: paymentTransferApi,
        method: "POST",
        body:post
      }),
    }),
 
  }),
});

export const {useGetWalletTransactionMutation,useGetRecieverNameQuery,useSetPaymentTransferMutation,useGetMyBalanceQuery} = walletApi;