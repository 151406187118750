import React from "react";
import { Link } from "react-router-dom";
import { logoutUrl } from "../constant/BaseUrl";

function Sidebar() {

  const handleLogout=()=>{
    window.location.href=logoutUrl
    localStorage.clear()
  }

  return (
    <div>
      <nav className="navbar   left_nav">
        <div className="collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav d-block">
            <li className="nav-item">
              <Link className={window.location.pathname==="/dashboard"?"nav-link active":"nav-link "} to="/dashboard">
                <i className="bi bi-grid"></i> Dashboard
              </Link>
            </li>
            <li className="nav-item">
              <Link className={window.location.pathname==="/my-affiliates"?"nav-link active":"nav-link "} to="/my-affiliates">
                <i className="bi bi-diagram-3"></i>My Affiliates
              </Link>
            </li>
            <li className="nav-item">
              <Link className={window.location.pathname==="/top-affiliates"?"nav-link active":"nav-link "} to="/top-affiliates">
                <i className="bi bi-diagram-2"></i>Top Affiliate
              </Link>
            </li>
          
            <li className="nav-item">
              <Link className={window.location.pathname==="/my-earning"?"nav-link active":"nav-link "} to="/my-earning">
                <i className="bi bi-currency-dollar"></i>My Earnings
              </Link>
            </li>
            <li className="nav-item">
              <Link className={window.location.pathname==="/referral"?"nav-link active":"nav-link "} to="/referral">
                <i className="bi bi-share-fill"></i>Referral
              </Link>
            </li>
            <li className="nav-item">
              <Link className={window.location.pathname==="/report"?"nav-link active":"nav-link "} to="/report">
                <i className="bi bi-layout-text-sidebar-reverse"></i>Report
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/material">
                <i className="bi bi-cloud-download"></i>Material Download
              </Link>
            </li>
            <li className="nav-item">
              <Link className={window.location.pathname==="/wallet"?"nav-link active":"nav-link "} to="/wallet">
                <i className="bi bi-wallet2"></i>My Wallet
              </Link>
            </li>
            <li className="nav-item">
              <Link className={window.location.pathname==="/announcement"?"nav-link active":"nav-link "}to="/announcement">
                <i className="bi bi-megaphone"></i>Announcement
              </Link>
            </li>
            <li className="nav-item">
              <Link className={window.location.pathname==="/reward"?"nav-link active":"nav-link "} to="/reward">
                <i className="bi bi-gift"></i>Rewards
              </Link>
            </li>
            <li className="nav-item">
              <Link className={window.location.pathname==="/agreement"?"nav-link active":"nav-link "} to="/agreement">
                <i className="bi bi-chevron-contract"></i>Agreement
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/">
                <i className="bi bi-shield-lock"></i>Security Center
              </Link>
            </li>
            <li className="nav-item">
              <button className="nav-link" onClick={handleLogout}>
                <i className="bi bi-box-arrow-right"></i>Logout
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Sidebar;
