import { getAffiliateEarningApi, getAffiliateInfoApi, getAffiliateReferralapi, getAffiliateReportApi, getTopAffiliateDetailsApi } from "../components/constant/Api";
import { myApi } from "./api";

export const affiliateApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getMyAffiliate: builder.mutation({
      query: (post) => ({
        url: getAffiliateInfoApi,
        method: "POST",
        body:post
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getTopAffiliate: builder.mutation({
      query: (post) => ({
        url: getTopAffiliateDetailsApi,
        method: "POST",
        body:post
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
    }),
    getAffiliateReport: builder.mutation({
      query: (post) => ({
        url: getAffiliateReportApi,
        method: "POST",
        body:post
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? []:[];
      },
    }),
    getAffiliateEarning: builder.mutation({
      query: (post) => ({
        url: getAffiliateEarningApi,
        method: "POST",
        body:post
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
    }),
    getAffiliateReferralUrl: builder.query({
      query: (post) => ({
        url: getAffiliateReferralapi,
        method: "Get",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" :"";
      },
    }),
  }),
});

export const {useGetMyAffiliateMutation,useGetTopAffiliateMutation,useGetAffiliateReportMutation,useGetAffiliateEarningMutation,useGetAffiliateReferralUrlQuery } = affiliateApi;