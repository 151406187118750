import React, { useState, useEffect } from "react";
import { useGetAnnouncementMutation } from "../../../redux/announcementApi";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import Sidebar from "../../widgets/Sidebar";
import { jwtDecode } from "jwt-decode";
import DescriptionModal from "../../partial/DescriptionModal";

function Announcement() {
  const token = localStorage.getItem("jwtToken");
  const [getAnnouncement, { data: record }] = useGetAnnouncementMutation();
  const [pageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [filterAnnouncement, setFilterAnnouncement] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [show, setShow] = useState(false)
  const [description, setDescription] = useState({})

  useEffect(() => {
    if (record) {
      setFilterAnnouncement(record);
    }
  }, [record]);

  useEffect(() => {
    if (pageNo) {
      getData();
    }
  }, [pageNo]);

  const getData = () => {
    const decode = jwtDecode(token);
    const sendData = {
      A_sAnnoucementIdJS: "",
      A_nStatus: 1,
      A_nPageNo: pageNo,
      A_nPageSize: pageSize,
      entity_id: decode.entityId,
    };
    getAnnouncement(sendData);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = record?.filter((item) =>
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(query.toLowerCase())
      )
    );
    setFilterAnnouncement(filtered);
  };

  const handleShow=(value)=>{
    setShow(true)
    setDescription(value)
  }

  return (
    <>
      <Header />
      <div className="man_container d-lg-flex">
        <Sidebar />
        <div className="right_container">
          <div className="container">
            <div className="d_box p-4 mb-4">
              <h4 className="w600 h55 mb-3">Announcement</h4>
              <div class="filter_box mb-4">
                <div class="row row8 align-items-center">
                  <div class="col-auto">Filter:</div>
                  <div class="col-md-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search by Name"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
              </div>

              <section className="section dashboard">
                <div className="card">
                  <div className="card-body pt-4">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Annoucement Id</th>
                            <th scope="col">Title</th>
                            <th scope="col">View</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filterAnnouncement?.map((list, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{list.sAnnouncementId}</td>
                                <td>{list.sTitle}</td>
                                <td><button className="btn btn-primary" onClick={()=>{handleShow(list)}}>
                                  View </button></td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex">
                      <nav aria-label="Page navigation example">
                        <ul class="pagination ml-auto">
                          <li class="page-item">
                            <button
                              class="page-link"
                              aria-label="Previous"
                              disabled={pageNo === 1}
                              onClick={() => {
                                setPageNo(pageNo - 1);
                              }}
                            >
                              <span aria-hidden="true">&laquo;</span>
                              {/* <span class="sr-only">Previous</span> */}
                            </button>
                          </li>
                          <li class="page-item">
                            <button class="page-link">{pageNo}</button>
                          </li>
                          <li class="page-item">
                            <button
                              class="page-link"
                              aria-label="Next"
                              onClick={() => {
                                setPageNo(pageNo + 1);
                              }}
                              disabled={record?.length !== 10}
                            >
                              <span aria-hidden="true">&raquo;</span>
                              {/* <span class="sr-only">Next</span> */}
                            </button>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </section>
              <DescriptionModal setShow={setShow} show={show} description={description} getData={getData}/>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Announcement;
