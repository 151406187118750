import moment from "moment";

export const affiliateEnum = [
  { key: "Self", value: 1 },
  { key: "Self + Affiliate", value: 3 },
];
export const allEnum = 0;
export const registrationEnum = 1;
export const USDTEnum = 1;
export const Level1Enum=1
export const AllLevelEnum=-1

export const yesterday = () => {
  const fromDate = new Date();
  fromDate.setDate(fromDate.getDate() - 1);
  return { from: moment(fromDate).format("YYYY-MM-DD HH:MM:SS"), to: moment(fromDate).format("YYYY-MM-DD HH:MM:SS") };
};

export const last7Days = () => {
  const fromDate = new Date();
  fromDate.setDate(fromDate.getDate() - 7);
  const toDate = new Date();
  return { from: moment(fromDate).format("YYYY-MM-DD HH:MM:SS"), to: moment(toDate).format("YYYY-MM-DD HH:MM:SS") };
};

export const last30Days = () => {
  const fromDate = new Date();
  fromDate.setDate(fromDate.getDate() - 30);
  return { from: moment(fromDate).format("YYYY-MM-DD HH:MM:SS"), to: moment(new Date()).format("YYYY-MM-DD HH:MM:SS")};
};

const currentDate = new Date(); // Get current date
const lastDateOfMonth = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth(),
  0
).getDate();

export const lastMonth = () => {
  const fromDate = new Date();
  fromDate.setMonth(fromDate.getMonth() - 1);
  fromDate.setDate(1);
  const toDate = new Date();
  toDate.setMonth(toDate.getMonth() - 1);
  toDate.setDate(lastDateOfMonth);
  return { from: moment(fromDate).format("YYYY-MM-DD HH:MM:SS"), to: moment(toDate).format("YYYY-MM-DD HH:MM:SS") };
};

export const thisYear = () => {
  const fromDate = new Date();
  fromDate.setMonth(0);
  fromDate.setDate(1);
  const toDate = new Date();
  toDate.setMonth(11);
  toDate.setDate(31);
  return { from: moment(fromDate).format("YYYY-MM-DD HH:MM:SS"), to: moment(toDate).format("YYYY-MM-DD HH:MM:SS") };
};

export const totalAll = () => {
  const fromDate = new Date();
  fromDate.setMonth(0);
  fromDate.setDate(1);
  fromDate.setFullYear(2010);
  const toDate = new Date();
  return { from: 0, to: moment(toDate).format("YYYY-MM-DD HH:MM:SS") };
};

export const convertNanoSecond = (date) => {
  // return date.getTime() * 10 ** 6;
  return moment(date).format("YYYY-MM-DD HH:MM:SS");
};

export const optionTypeEnum = [
  {
    key: "All",
    value: 0,
  },
  {
    key: "Registration",
    value: 1,
  },
  { key: "Membership", value: 2 },
  { key: "Virtual Card", value: 3 },
  { key: "BOT", value: 4 },
  { key: "MMTR", value: 5 },
];

export const commTypeEnum = [
  {
    key: "USDT",
    value: 1,
  },
  { key: "GZUT", value: 2 },
];

export const levelEnum = [
  {
    key: "Level1",
    value: 1,
  },
  { key: "Level2", value: 2 },
  { key: "Level3", value: 3 },
  { key: "Level4", value: 4 },
  { key: "All", value: -1 },
];
export const membershipEnum = 2;

export const availableEnum=[{
  key:"USDT",
  value:1,
},{
  key:"GZUT",
  value:900
}]
