import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useGetMyAffiliateMutation } from "../../redux/affiliateApi";
import { convertNanoSecond, Level1Enum } from "../constant/enum";

function TreeModal(props) {
  const [getMyAffiliate] = useGetMyAffiliateMutation();
  const [mainData, setMainData] = useState({});
  const [mainEntity, setMainEntity] = useState("");
  const [levelOneEntity, setLevelOneEntity] = useState("");
  const [levelTwoEntity, setLevelTwoEntity] = useState("");
  const [levelThreeEntity, setLevelThreeEntity] = useState("");
  const [levelOneData, setLevelOneData] = useState([]);
  const [levelTwoData, setLevelTwoData] = useState([]);
  const [levelThreeData, setLevelThreeData] = useState([]);
  const [levelFourData, setLevelFourData] = useState([]);

  useEffect(() => {
    if (props) {
      setMainData(props.currentRecord);
      setMainEntity(props.currentRecord.nEntityId);
    }
  }, [props]);

  useEffect(() => {
    if (mainEntity) {
      const sendData = {
        A_nLevel: parseInt(Level1Enum),
        A_nFromTime:
          props.startDate === "0"
            ? props.startDate
            : convertNanoSecond(new Date(props.startDate)),
        A_nToTime: convertNanoSecond(new Date(props.endDate)),
        entityId: mainEntity,
      };
      getLevelOnedata(sendData);
    }
  }, [mainEntity]);

  const getLevelOnedata = async (sendData) => {
    const data = await getMyAffiliate(sendData);
    setLevelOneData(data.data[1]);
  };

  //   console.log(levelOneData,"levelOneData")

  const handleClose = () => {
    props.setShow(false);
    setMainData({});
    setMainEntity("");
    setLevelOneEntity("");
    setLevelTwoEntity("");
    setLevelThreeEntity("");
    setLevelOneData([]);
    setLevelTwoData([]);
    setLevelThreeData([]);
    setLevelFourData([]);
  };

  const handleLevelTwo = async (entId) => {
    setLevelOneEntity(entId);
    const sendData = {
      A_nLevel: parseInt(Level1Enum),
      A_nFromTime:
        props.startDate === "0"
          ? props.startDate
          : convertNanoSecond(new Date(props.startDate)),
      A_nToTime: convertNanoSecond(new Date(props.endDate)),
      entityId: entId,
    };
    const data = await getMyAffiliate(sendData);
    setLevelTwoData(data.data[1]);
    setLevelThreeData([]);
  };
  const handleLevelThree = async (entId) => {
    setLevelTwoEntity(entId);
    const sendData = {
      A_nLevel: parseInt(Level1Enum),
      A_nFromTime:
        props.startDate === "0"
          ? props.startDate
          : convertNanoSecond(new Date(props.startDate)),
      A_nToTime: convertNanoSecond(new Date(props.endDate)),
      entityId: entId,
    };
    const data = await getMyAffiliate(sendData);
    setLevelThreeData(data.data[1]);
  };

  //   const handleLevelFour = async (entId) => {
  //     setLevelThreeEntity(entId);
  //     const sendData = {
  //       A_nLevel: parseInt(Level1Enum),
  //       A_nFromTime:
  //         props.startDate === "0"
  //           ? props.startDate
  //           : convertNanoSecond(new Date(props.startDate)),
  //       A_nToTime: convertNanoSecond(new Date(props.endDate)),
  //       entityId: entId,
  //     };
  //     const data = await getMyAffiliate(sendData);
  //     setLevelFourData(data.data[1]);
  //   };

  return (
    <div>
      {" "}
      <Modal show={props.show} onHide={handleClose} dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>Hierarchy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="p-md-2 p-4" style={{ overflow: "auto", height: " 60vh" }}>
            <div class="d-flex">
              <div class="tree_box before_none active">
              <div class="tree_box_in after_none ">
                <a class="btn btn-primary" href="#">
                  {mainData?.sFullName}
                </a>
                <div class="popop_box">
                  <p>
                    
                    <b>Entity ID : </b>
                    {mainData.nEntityId}
                  </p>
                  <p>
                    
                    <b>Name: </b>
                    {mainData.sFullName}
                  </p>
                  <p>
                    
                    <b>Email : </b>
                    {mainData.sEMailId}
                  </p>
                  <p>
                    
                    <b>Mobile No : </b>
                    {mainData.sMobileNo}
                  </p>
                </div>
              </div>
              </div>
              <div
                class={`tree_box ${levelOneData?.length > 0 ? "active" : ""}`}
              >
                {levelOneData?.map((list) => {
                  return (
                    <div
                      class={`tree_box_in ${
                        list.nReferredEntityId == levelOneEntity ? "active" : ""
                      }`}
                      onClick={() => {
                        handleLevelTwo(list.nReferredEntityId);
                      }}
                    >
                      {" "}
                      <a class="btn btn-primary" href="#">
                        {list.sReferredEntityName}
                      </a>
                      <div class="popop_box">
                        <p>
                          {" "}
                          <b>Entity ID : </b>
                          {list.nReferredEntityId}{" "}
                        </p>
                        <p>
                          {" "}
                          <b>Name: </b>
                          {list.sReferredEntityName}{" "}
                        </p>
                        <p>
                          {" "}
                          <b>Email : </b>
                          {list.sReferredEntityEmailId}{" "}
                        </p>
                        <p>
                          {" "}
                          <b>Mobile No : </b>
                          {list.sReferredEntityMobileNo}{" "}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                class={`tree_box ${levelTwoData?.length > 0 ? "active" : ""}`}
              >
                {levelTwoData?.map((list) => {
                  return (
                    <div
                      class={`tree_box_in ${
                        list.nReferredEntityId == levelTwoEntity ? "active" : ""
                      }`}
                      onClick={() => {
                        handleLevelThree(list.nReferredEntityId);
                      }}
                    >
                      {" "}
                      <a class="btn btn-primary" href="#">
                        {list.sReferredEntityName}
                      </a>
                      <div class="popop_box">
                        <p>
                          {" "}
                          <b>Entity ID : </b>
                          {list.nReferredEntityId}{" "}
                        </p>
                        <p>
                          {" "}
                          <b>Name: </b>
                          {list.sReferredEntityName}{" "}
                        </p>
                        <p>
                          {" "}
                          <b>Email : </b>
                          {list.sReferredEntityEmailId}{" "}
                        </p>
                        <p>
                          {" "}
                          <b>Mobile No : </b>
                          {list.sReferredEntityMobileNo}{" "}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                class={`tree_box ${levelThreeData?.length > 0 ? "active" : ""}`}
              >
                {levelThreeData?.map((list) => {
                  return (
                    <div
                      class={`tree_box_in ${
                        list.nReferredEntityId == levelThreeEntity
                          ? "active"
                          : ""
                      }`}
                    >
                      {" "}
                      <a class="btn btn-primary" href="#">
                        {list.sReferredEntityName}
                      </a>
                      <div class="popop_box">
                        <p>
                          {" "}
                          <b>Entity ID : </b>
                          {list.nReferredEntityId}{" "}
                        </p>
                        <p>
                          {" "}
                          <b>Name: </b>
                          {list.sReferredEntityName}{" "}
                        </p>
                        <p>
                          {" "}
                          <b>Email : </b>
                          {list.sReferredEntityEmailId}{" "}
                        </p>
                        <p>
                          {" "}
                          <b>Mobile No : </b>
                          {list.sReferredEntityMobileNo}{" "}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default TreeModal;
