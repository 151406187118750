import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useGetAnnouncementMutation } from "../../redux/announcementApi";
import { jwtDecode } from "jwt-decode";
import DescriptionModal from "../partial/DescriptionModal";

function Header() {
  const token = localStorage.getItem("jwtToken");
  const [date] = useState(new Date());
  const formattedDate = date.toLocaleString();
  const [getAnnouncement, { data: record }] = useGetAnnouncementMutation();
  const [show, setShow] = useState(false);
  const [description, setDescription] = useState({});

  const handleShow = (value) => {
    setShow(true);
    setDescription(value);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const decode = jwtDecode(token);
    const sendData = {
      A_sAnnoucementIdJS: "",
      A_nStatus: 1,
      A_nPageNo: 1,
      A_nPageSize: 5,
      entity_id: decode.entityId,
    };
    getAnnouncement(sendData);
  };

  return (
    <div className="home_nav d-flex align-items-center">
      <Link className="navbar-brand" to="/">
        <img src="/assets/img/logo.svg" alt="Logo" className="logo" />
      </Link>
      <div className="top_right ml-auto d-sm-flex align-items-center">
        <span className="mobile_top_fix">Last Update: {formattedDate}</span>
        <div className=" d-flex  align-items-center ">
          <div className="ml-3" id="google_translate_element"></div>
          <div className="notification">
            <Link className="nav-link" to="#">
              <i className="bi bi-bell-fill"></i>
            </Link>
            <div className="noti-drop">
              <ul>
                {record?.map((list, index) => {
                  return (
                    <li
                      onClick={() => {
                        handleShow(list);
                      }}
                      style={{
                        background: list.nReadStatus == 1 ? "#ebe8e8" : "",
                      }}
                    >
                      <h5>{list.sTitle}</h5>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <Link className="user_top ml-lg-3 ml-1 mr-lg-0 mr-1" to="/dashboard">
            <img src="/assets/img/user.jpg" alt="" />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
          >
            <i className="bi bi-list"></i>
          </button>
        </div>
      </div>
      <DescriptionModal
        setShow={setShow}
        show={show}
        description={description}
        getData={getData}
      />
    </div>
  );
}

export default Header;
