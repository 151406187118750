import {getMaterialapi } from "../components/constant/Api";
import { myApi } from "./api";

export const materialApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getMaterial: builder.mutation({
      query: (post) => ({
        url: getMaterialapi,
        method: "POST",
        body:post
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
    }),
  }),
});

export const {useGetMaterialMutation} = materialApi;