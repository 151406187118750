import {getAnnouncementApi, readAnnouncementApi } from "../components/constant/Api";
import { myApi } from "./api";

export const announcementApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getAnnouncement: builder.mutation({
      query: (post) => ({
        url: getAnnouncementApi,
        method: "POST",
        body:post
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
    }),
    readAnnouncement: builder.mutation({
      query: (post) => ({
        url: readAnnouncementApi,
        method: "POST",
        body:post
      }),
    }),
  }),
});

export const {useGetAnnouncementMutation,useReadAnnouncementMutation} = announcementApi;