import React from "react";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import Sidebar from "../../widgets/Sidebar";
import History from "./History";
import MyBalance from "./MyBalance";
import WithDraw from "./WithDraw";

function Wallet() {
  return (
    <>
      <Header />
      <div className="man_container d-lg-flex">
        <Sidebar />
        <div className="right_container">
          <div className="container">
            <MyBalance />
            <WithDraw />
            <History />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Wallet;
