import moment from "moment";
import React, { useEffect, useState } from "react";
import { useGetMyAffiliateMutation } from "../../../redux/affiliateApi";
import {
  AllLevelEnum,
  convertNanoSecond,
  Level1Enum,
  levelEnum,
} from "../../constant/enum";
import { exportToExcel } from "../../constant/exportToExcel";
import {jwtDecode} from "jwt-decode";
import Loader from "../../constant/Loader";
import TreeModal from "../../partial/TreeModal";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import Sidebar from "../../widgets/Sidebar";
import Pagination from "../Pagination";

function MyAffiliates() {
  const token=localStorage.getItem('jwtToken')
  const [getMyAffiliate, { data }] = useGetMyAffiliateMutation();
  const [level, setLevel] = useState(AllLevelEnum);
  const [startDate, setStartDate] = useState("0");
  const [filterMyAffilate, setFilterMyAffilate] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentRecord, setCurrentRecord] = useState({})
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [show, setShow] = useState(false)

  useEffect(() => {
    setTenYearDate();
  }, []);

  const setTenYearDate = () => {
    const tenYear = new Date();
    tenYear.setMonth(0);
    tenYear.setDate(1);
    tenYear.setFullYear(2010);
    // Format tomorrow's date as needed (e.g., YYYY-MM-DD)
    const tenYearFormatted = tenYear.toISOString().split("T")[0];
    setStartDate(tenYearFormatted);
  };

  useEffect(() => {
    if (level && startDate && endDate) {
      const decode=jwtDecode(token)
      const sendData = {
        A_nLevel: parseInt(level),
        A_nFromTime:
          startDate === "0"
            ? startDate
            : convertNanoSecond(new Date(startDate)),
        A_nToTime: convertNanoSecond(new Date(endDate)),
        entityId:decode.entityId
      };
      getMyAffiliate(sendData);
    }
  }, [level]);

  useEffect(() => {
    if (data) {
      setFilterMyAffilate(data[0]);
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "startDate") {
      setStartDate(value);
    }
    if (name === "endDate") {
      setEndDate(value);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const decode=jwtDecode(token)
    const sendData = {
      A_nLevel: parseInt(level),
      A_nFromTime: convertNanoSecond(new Date(startDate)),
      A_nToTime: convertNanoSecond(new Date(endDate)),
      entityId:decode.entityId
    };
    getMyAffiliate(sendData);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = data?.filter((item) =>
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(query.toLowerCase())
      )
    );
    setFilterMyAffilate(filtered);
  };
  const handleExcel = () => {
    if (filterMyAffilate.length > 0) {
      exportToExcel(
        filterMyAffilate,
        `my_affiliate_${moment(new Date()).format("DD-MM-YYYY,HH:mm:ss")}`
      );
    } else {
      let arr = [
        {
          ReportType: "",
          nEntityId: "",
          sFullName: "",
          sReferralId: "",
          sEMailId: "",
          sMobileNo: "",
          nLevel: "",
          UserCount: "",
          EarningInUSDT: "",
          EarningInGZUT: "",
        },
      ];
      exportToExcel(
        arr,
        `my_affiliate_${moment(new Date()).format("DD-MM-YYYY,HH:mm:ss")}`
      );
    }
  };

  const totalPages = Math.ceil(filterMyAffilate?.length / itemsPerPage);

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Calculate the start and end indices for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the data for the current page
  const currentPageData = filterMyAffilate?.slice(startIndex, endIndex);

  const handleShow=(record)=>{
    setShow(true)
    setCurrentRecord(record)
  }

  return (
    <>
      <Header />
      <div className="man_container d-lg-flex">
        <Sidebar />
        <div className="right_container">
          <div className="container">
            <div className="w_box p-md-4 p-3 mb-4">
              <div className="filter_box mb-4">
                <div className="row row8 align-items-center">
                  <div className="col-md-auto ">Filter:</div>
                  <div className="col-md-3 col-lg-2 col-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by Name"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>
                  <div className="col-md-auto  col-6">
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setLevel(e.target.value);
                      }}
                      value={level}
                    >
                      {/* <option>Level</option> */}
                      {levelEnum.map((list) => {
                        return <option value={list.value}>{list.key}</option>;
                      })}
                    </select>
                  </div>
                  {/* <div className="col-md-auto  col-2">
                    <button type="button" className="btn ">
                      All
                    </button>
                  </div> */}
                  <div className="col-md-3 col-lg-2 col-5">
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Start Date"
                      value={startDate}
                      name="startDate"
                      min="2010-01-01"
                      onChange={handleChange}
                      max={new Date().toISOString().substr(0, 10)}
                    />
                  </div>
                  <div className="col-md-auto col-2 text-center">To</div>

                  <div className="col-md-3 col-lg-2 col-5">
                    <input
                      type="date"
                      value={endDate}
                      className="form-control"
                      placeholder="End date"
                      name="endDate"
                      onChange={handleChange}
                      min={startDate}
                      max={new Date().toISOString().substr(0, 10)}
                    />
                  </div>
                  <div className="col-auto">
                    <button
                      type="button"
                      className="btn "
                      onClick={handleSearch}
                    >
                      Search
                    </button>
                  </div>
                  <div className="col-auto">
                    <button
                      type="button"
                      className="btn "
                      onClick={handleExcel}
                    >
                      Export
                    </button>
                  </div>
                </div>
              </div>
              <h5 className="w600 h55 mb-3">List of Members</h5>
              <div className="table-responsive">
                <table className="table affiliate-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Registration ID</th>
                      <th>No. of users under him</th>
                      <th>Level</th>
                      <th>Total Earning (USD)</th>
                      <th>Total Earning (GZUT)</th>
                      <th>Email ID</th>
                      <th>Mobile No.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentPageData?.length==0 &&    <Loader />}
                    {currentPageData?.map((list) => {
                      return (
                        <tr>
                          <td ><span onClick={()=>{handleShow(list)}}>{list.sFullName}</span></td>
                          <td>{list.nEntityId}</td>
                          <td>{list.UserCount}</td>
                          <td>{list.nLevel}</td>
                          <td>{list.EarningInUSDT}</td>
                          <td>{list.EarningInGZUT}</td>
                          <td>{list.sEMailId}</td>
                          <td>{list.sMobileNo}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
            </div>
          </div>
          <TreeModal setShow={setShow} show={show} currentRecord={currentRecord}  startDate={startDate}  endDate={endDate}/>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default MyAffiliates;
