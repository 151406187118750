import React from "react";
import { useGetMyBalanceQuery } from "../../../redux/walletApi";

function MyBalance() {
  const {data}=useGetMyBalanceQuery()
  console.log(data,"data")
  return (
    <>
      <div className="w_box p-md-4 p-3 mb-4">
        <h5 className="w600 h55 mb-3">Current Wallet Balance</h5>
        <div className="row">
          <div className="col-md-3 mb-4 mb-md-0">
            <div className="w_box_border  p-3">
              <p className="mb-2">Total USDT</p>
              <h4 className="w600 mb-0">{data && parseFloat(data[0]?.USDT?data[0]?.USDT:0).toFixed(2)}</h4>
            </div>
          </div>
          <div className="col-md-3 ">
            <div className="w_box_border  p-3">
              <p className="mb-2">Total GZUT</p>
              <h4 className="w600 mb-0">{data && parseFloat(data[0]?.GZUT?data[0]?.GZUT:0).toFixed(2)}</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyBalance;
