import { baseUrl } from "./BaseUrl";

export const loginApi = baseUrl + "/login-third-party";

//============================ Dashboard =========================== 
export const getAffiliateDashboardApi="/get-affiliate-dashboard"
export const getAffiliateDashboardLineChartApi="/get-affiliate-dashboard-line-chart"

//===================== Affiliate ======================= 
export const getAffiliateInfoApi="/get-affiliate-info"
export const getAffiliateReportApi="/get-affiliate-report"
export const getTopAffiliateDetailsApi="/get-top-affiliate-details"
export const getAffiliateReferralapi="/get-affiliate-referral-url"
export const getAffiliateEarningApi="/get-affiliate-my-earning"

//================= Transaction ==========================
export const getWalletTransactionApi="/get-wallet-transaction-history"
export const getRecieverNameApi="/get-reciever-name"
export const paymentTransferApi="/payment-transfer"
export const getMyBalanceApi="/get-mybalance"

//=================== Material ==================
export const getMaterialapi="/get-material"

//==================== Announcement ===============
export const getAnnouncementApi="/get-announcement"
export const readAnnouncementApi="/read-announcement"