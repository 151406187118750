import React,{useEffect} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useReadAnnouncementMutation } from "../../redux/announcementApi";

function DescriptionModal(props) {
  const [readAnnouncement]=useReadAnnouncementMutation()
    const handleClose = () => {
        props.setShow(false);
      };
    
    useEffect(() => {
      if(props?.description?.nReadStatus==1){
        console.log("call")
        const data = {
          A_sAnnoucementIdJS: props?.description.sAnnouncementId,
          A_sTitle: props?.description.sTitle,
          A_sDescription: props?.description.sDescription,
          A_sData:props?.description.sData,
          A_sAffiliateEntityId:props?.description.nAffiliateEntityId
        };
        readAnnouncement(data).then((result) => {
          if (result.data.status) {
            props.getData()
          }
        });
      }
    }, [props.description])
      
    
  return (
    <div>
      {" "}
      <Modal show={props.show} onHide={handleClose} dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
             <p>{props?.description?.sDescription}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DescriptionModal;
