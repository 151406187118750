import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "./AuthContext";
import ProtectedRoute from "./ProtectedRoute";
import Login from "./components/auth/Login";
import Agreement from "./components/pages/agreement/Agreement";
import Announcement from "./components/pages/announcement/Announcement";
import MyAffiliates from './components/pages/myAffiliates/MyAffiliates';
import MyEarning from "./components/pages/myEarning/MyEarning";
import Referral from "./components/pages/referral/Referral";
import Report from "./components/pages/report/Report";
import Reward from "./components/pages/reward/Reward";
import TopAffilates from './components/pages/topAffiliates/TopAffilates';
import Wallet from "./components/pages/wallet/Wallet";
import Dashboard from './components/widgets/Dashboard';
import Material from "./components/pages/material/Material";

function App() {
  const { login } = useAuth();

  useEffect(() => {
    // Check if a JWT token is stored in localStorage and log in the user if found
    const jwtToken = localStorage.getItem("jwtToken");
    if (jwtToken) {
      login();
    }
  }, [login]);

  return (
    <div className="App">
         <Router>
        <Routes>
        <Route path="/" element={<Login/>} /> 
        <Route
              path="/dashboard"
              element={<ProtectedRoute component={<Dashboard />} />}
            />
             <Route
              path="/top-affiliates"
              element={<ProtectedRoute component={<TopAffilates />} />}
            />
             <Route
              path="/my-affiliates"
              element={<ProtectedRoute component={<MyAffiliates />} />}
            />
            <Route
              path="/my-earning"
              element={<ProtectedRoute component={<MyEarning />} />}
            />
            <Route
              path="/report"
              element={<ProtectedRoute component={<Report />} />}
            />
            <Route
              path="/referral"
              element={<ProtectedRoute component={<Referral />} />}
            />
            <Route
              path="/wallet"
              element={<ProtectedRoute component={<Wallet />} />}
            />
              <Route
              path="/reward"
              element={<ProtectedRoute component={<Reward />} />}
            />
              <Route
              path="/announcement"
              element={<ProtectedRoute component={<Announcement />} />}
            />
              <Route
              path="/agreement"
              element={<ProtectedRoute component={<Agreement />} />}
            />
              <Route
              path="/material"
              element={<ProtectedRoute component={<Material />} />}
            />
          {/* <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/top-affiliates" element={<TopAffilates />} />
          <Route path="/my-affiliates" element={<MyAffiliates />} /> */}
          {/* <Route path="/my-earning" element={<MyEarning />} />
          <Route path="/report" element={<Report />} />
          <Route path="/referral" element={<Referral />} />
          <Route path="/wallet" element={<Wallet />} /> */}
          {/* <Route path="/reward" element={<Reward />} />
          <Route path="/announcement" element={<Announcement />} />
          <Route path="/agreement" element={<Agreement />} /> */}
        </Routes>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
