import moment from "moment";
import React, { useState, useEffect } from "react";
import { useGetWalletTransactionMutation } from "../../../redux/walletApi";
import { convertNanoSecond } from "../../constant/enum";
import { exportToExcel } from "../../constant/exportToExcel";

function History() {
  const [getWalletTransaction, { data }] = useGetWalletTransactionMutation();
  const [startDate, setStartDate] = useState("0");
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [pageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    setTenYearDate();
  }, []);

  const setTenYearDate = () => {
    const tenYear = new Date();
    tenYear.setMonth(0);
    tenYear.setDate(1);
    tenYear.setFullYear(2010);
    // Format tomorrow's date as needed (e.g., YYYY-MM-DD)
    const tenYearFormatted = tenYear.toISOString().split("T")[0];
    setStartDate(tenYearFormatted);
  };

  useEffect(() => {
    if (startDate && endDate) {
      const sendData = {
        A_nFromTime:
          startDate === "0"
            ? startDate
            : convertNanoSecond(new Date(startDate)),
        A_nToTime: convertNanoSecond(new Date(endDate)),
        pageNo: pageNo,
        pageSize: pageSize,
      };
      getWalletTransaction(sendData);
    }
  }, [pageNo]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "startDate") {
      setStartDate(value);
    }
    if (name === "endDate") {
      setEndDate(value);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const sendData = {
      A_nFromTime: convertNanoSecond(new Date(startDate)),
      A_nToTime: convertNanoSecond(new Date(endDate)),
      pageNo: 1,
      pageSize: pageSize,
    };
    getWalletTransaction(sendData);
  };

  const handleExcel = () => {
    if (data.length > 0) {
      exportToExcel(
        data,
        `wallet_transaction_history_${moment(new Date()).format(
          "DD-MM-YYYY,HH:mm:ss"
        )}`
      );
    } else {
      let arr = [
        {
          dDate: "",
          sTransactionDetails: "",
          sTransactionId: "",
          Credit_USDT: "",
          Credit_GZUT: "",
          Debit_USDT: "",
          Debit_GZUT: "",
        },
      ];
      exportToExcel(
        arr,
        `wallet_transaction_history_${moment(new Date()).format(
          "DD-MM-YYYY,HH:mm:ss"
        )}`
      );
    }
  };

  return (
    <>
      <div className="w_box p-md-4 p-3 mb-4">
        <div className="filter_box mb-4">
          <div className="row row8 align-items-center">
            <div className="col-auto">Filter:</div>
            <div className="col-md-3 col-lg-3 ml-auto">
              <input
                type="date"
                className="form-control"
                placeholder="Start Date"
                value={startDate}
                name="startDate"
                onChange={handleChange}
                min="2010-01-01"
                max={new Date().toISOString().substr(0, 10)}
              />
            </div>
            <div className="col-auto">To</div>
            <div className="col-md-3 col-lg-3 ">
              <input
                type="date"
                value={endDate}
                className="form-control"
                placeholder="End date"
                name="endDate"
                onChange={handleChange}
                min={startDate}
                max={new Date().toISOString().substr(0, 10)}
              />
            </div>
            <div className="col-auto">
              <button type="button" className="btn " onClick={handleSearch}>
                <i className="bi bi-search"></i>
              </button>
            </div>
            <div className="col-auto">
              <button type="button" className="btn " onClick={handleExcel}>
                <i className="bi bi-download"></i>
              </button>
            </div>
          </div>
        </div>
        <h5 className="w600 h55 mb-3">Credit Transaction History</h5>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th rowspan="2">Date</th>
                <th rowspan="2">Txn Details</th>
                <th rowspan="2">Transaction ID </th>
                <th colspan="2" className="text-center">
                  Credit{" "}
                </th>
                <th colspan="2" className="text-center">
                  Debit
                </th>
              </tr>
              <tr>
                <th>USDT </th>
                <th>GZUT</th>
                <th>USDT</th>
                <th>GZUT</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((list) => {
                return (
                  <tr>
                    <td>{moment(list.dDate).format("DD-MM-YYYY")} </td>
                    <td> {list.sTransactionDetails} </td>
                    <td> {list.sTransactionId} </td>
                    <td>{list.Credit_USDT}</td>
                    <td> {list.Credit_GZUT} </td>
                    <td> {list.Debit_USDT} </td>
                    <td> {list.Debit_GZUT} </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div>
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <button class="page-link" aria-label="Previous" disabled={pageNo===1} onClick={()=>{setPageNo(pageNo-1)}}>
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Previous</span>
                </button>
              </li>
              <li class="page-item">
                <button class="page-link">{pageNo}</button>
              </li>
              <li class="page-item">
                <button class="page-link" aria-label="Next" onClick={()=>{setPageNo(pageNo+1)}} disabled={data?.length!==10}>
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Next</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default History;
