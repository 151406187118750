import moment from "moment";
import React, { useEffect, useState } from "react";
import { useGetAffiliateDashboardLineChartMutation } from "../../redux/dashboardApi";
import { allEnum, convertNanoSecond } from "../constant/enum";
import Chart from "./Chart";

function Graphs({ selectMarketing, optionTypeEnum, Loader, totalAll }) {
  const [getAffiliateDashboardLineChart, { isLoading }] =
    useGetAffiliateDashboardLineChartMutation();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(new Date());
  const [data, setData] = useState([]);
  useEffect(() => {
    if (selectMarketing && optionTypeEnum?.length>0) {
      getDataFun();
      setYesterdayDate();
      setTenYearDate();
    }
  }, [selectMarketing,optionTypeEnum]);

  const setTenYearDate = () => {
    const tenYear = new Date();
    tenYear.setMonth(0);
    tenYear.setDate(1);
    tenYear.setFullYear(2010);
    // Format tomorrow's date as needed (e.g., YYYY-MM-DD)
    const tenYearFormatted = tenYear.toISOString().split("T")[0];
    setStartDate(tenYearFormatted);
  };

  const setYesterdayDate = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    // Format tomorrow's date as needed (e.g., YYYY-MM-DD)
    const yesterdayFormatted = yesterday.toISOString().split("T")[0];
    setEndDate(yesterdayFormatted);
  };

  const getDataFun = async () => {
    setData([]);
    const value = totalAll();
    const type = optionTypeEnum;
    // for (let i = 0; i < type.length; i++) {
      const sendData = {
        A_nOptType:allEnum,
        A_nOptUserType: selectMarketing,
        A_nFromTime: value.from,
        A_nToTime: value.to,
      };
      let result = await getAffiliateDashboardLineChart(sendData);
      if (result?.data?.length > 0) {
        for (let i = 0; i < type.length; i++) {
          const data=result.data.filter((list)=>{return list.nOptType==type[i].value})
          if(data.length>0){
            let lables = data.map((item) =>
              moment(item.Date).format("DD-MM-yy")
            );
            let commissionUSDT = data.map((item) => item.CommissionUSDT).filter(value => value > 0);
            let commissionGZUT = data.map((item) => item.CommissionGZUT).filter(value => value > 0);
            let formattedRecord = [
              lables,
              {
                label: "CommissionUSDT",
                data: commissionUSDT,
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
              },
              {
                label: "CommissionGZUT",
                data: commissionGZUT,
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
              },
            ];
    
            let finalResult = {
              reportName: type[i].key,
              record: formattedRecord,
            };
            setData((prevData) => [...prevData, finalResult]);
          }
      }
      }
    // }
    setYesterdayDate();
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setData([]);
    const type = optionTypeEnum;
  
      const sendData = {
        A_nOptType:allEnum,
        A_nOptUserType: selectMarketing,
        A_nFromTime: convertNanoSecond(new Date(startDate)),
        A_nToTime: convertNanoSecond(new Date(endDate)),
      };
      let result = await getAffiliateDashboardLineChart(sendData);
      if (result.data.length > 0) {
        for (let i = 0; i < type.length; i++) {
          const data=result.data.filter((list)=>{return list.nOptType==type[i].value})
          if(data.length>0){
            let lables = result.data.map((item) =>
              moment(item.Date).format("DD-MM-yy")
            );
            let commissionUSDT = result.data.map((item) => item.CommissionUSDT);
            let commissionGZUT = result.data.map((item) => item.CommissionGZUT);
            let formattedRecord = [
              lables,
              {
                label: "CommissionUSDT",
                data: commissionUSDT,
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
              },
              {
                label: "CommissionGZUT",
                data: commissionGZUT,
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
              },
            ];
    
            let finalResult = {
              reportName: type[i].key,
              record: formattedRecord,
            };
            setData((prevData) => [...prevData, finalResult]);
          }
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "startDate") {
      setStartDate(value);
    }
    if (name === "endDate") {
      setEndDate(value);
    }
  };

  return (
    <div className="w_box p-md-4 p-3">
      <div className="filter_box">
        <div className="row align-items-center">
          {/* <form onSubmit={handlesubmit}> */}
          <div className="col-md-3 col-lg-3 col-5">
            <input
              type="date"
              className="form-control"
              placeholder="  Start date   "
              value={startDate}
              name="startDate"
              onChange={handleChange}
              min="2010-01-01"
              max={new Date().toISOString().substr(0, 10)}
            />
          </div>
          <div className="col-auto col-2 text-center">To</div>
          <div className="col-md-3 col-lg-3 col-5">
            <input
              type="date"
              className="form-control"
              placeholder="End date"
              value={endDate}
              name="endDate"
              onChange={handleChange}
              min={startDate}
              max={new Date().toISOString().substr(0, 10)}
            />
          </div>
          <div className="col-auto mt-md-0 mt-3">
            <button type="submit" className="btn " onClick={handlesubmit}>
              Search
            </button>
          </div>
          {/* </form> */}
          {/* <div className="col-auto">
            <button type="button" className="btn " onClick={getDataFun}>
              Yesterday
            </button>
          </div> */}
        </div>
      </div>
      <div className="mt-4 row">
        {data?.map((list) => {
          return (
            <div className=" col-md-6 mb-4 ">
              <div className="  w_box_border p-3">
                <Chart reportName={list.reportName} record={list.record} />
              </div>
            </div>
          );
        })}
        {isLoading && (
          <div className=" col-md-6 mb-4 ">
            <div className="  w_box_border p-3">
              <Loader />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Graphs;
