import React,{useState} from "react";
import { useGetAffiliateReferralUrlQuery } from "../../../redux/affiliateApi";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import Sidebar from "../../widgets/Sidebar";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import Pagination from "../Pagination";

function Referral() {
  const { data } = useGetAffiliateReferralUrlQuery();
  const [isDropDown, setIsDropDown] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const copyToClipboard = (link) => {
    if (link) {
      toast.success("Referral link copied successfully");
      copy(link);
    }
  };

  const totalPages = Math.ceil(data && data[1]?.length / itemsPerPage);

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the data for the current page
  const currentPageData = data && data[1]?.slice(startIndex, endIndex);

  return (
    <>
      <Header />
      <div className="man_container d-lg-flex">
        <Sidebar />
        <div className="right_container">
          <div className="container">
            <div className="w_box p-md-4 p-3 mb-4">
              <h5 className="w600 h55 mb-3">Referral link</h5>
              <div className=" mb-md-4">
                <div className="row  align-items-center">
                  <div className="col-md-8 mb-md-0 mb-3">
                    <div className="w_box_border   p-2 pl-3 pr-3 wbba">
                      {data && data[0][0]?.AffiliateURL}
                    </div>
                  </div>
                  <div className="col-auto">
                    <button
                      type="button"
                      className="btn"
                      onClick={() => {
                        copyToClipboard(data && data[0][0]?.AffiliateURL);
                      }}
                    >
                      <i className="bi bi-copy"></i>
                    </button>
                  </div>
                  <div className="col-auto">
                  <div className="position-relative">
                    <button
                      type="button"
                      className="btn"
                      onClick={()=>{setIsDropDown(!isDropDown)}}
                    >
                      <i className="bi bi-share-fill"></i>
                    </button>
                    <div
                      className={`new_drop ${isDropDown?"active":""}`}
                     
                    >
                      <a className="dropdown-item" target="_blank" href={`https://api.whatsapp.com/send?l=en&text=${data && data[0][0]?.AffiliateURL}`}>
                      <i className="bi bi-whatsapp"></i> whatsapp
                      </a>
                     
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w_box p-md-4 p-3 mb-4">
              <h5 className="w600 h55 mb-3">Partner Referral Links</h5>
              <div className="table-responsive">
                <table className="table ">
                  <thead>
                    <tr>
                      <th>Referral Code</th>
                      <th>Link</th>
                      <th>% Discount</th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentPageData?.map((list) => {
                      return (
                        <tr>
                          <td>{list.PromoCode}</td>
                          <td>{list.AffiliateURL}</td>
                          <td> {list.DiscountValue}</td>
                          <td>
                            <div className="d-flex">
                              <button
                                type="button"
                                className="btn btn-small "
                                onClick={() => {
                                  copyToClipboard(list.AffiliateURL);
                                }}
                              >
                                <i className="bi bi-copy"></i>
                              </button>
                              <button
                                type="button"
                                className="btn btn-small ml-1"
                              >
                                <i className="bi bi-share-fill"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Referral;
