import React, { useState, useEffect } from "react";
import { useGetAffiliateDashboardMutation } from "../../redux/dashboardApi";
import {
  affiliateEnum,
  allEnum,
  last30Days,
  last7Days,
  lastMonth,
  membershipEnum,
  optionTypeEnum,
  thisYear,
  totalAll,
  yesterday,
} from "../constant/enum";
import Loader from "../constant/Loader";
import Banner from "./Banner";
import Footer from "./Footer";
import Graphs from "./Graphs";
import Header from "./Header";
import Sidebar from "./Sidebar";

function Dashboard() {
  const [getAffiliateDashboard, { isLoading }] =
    useGetAffiliateDashboardMutation();
  const [selectMarketing, setSelectMarketing] = useState("1");
  const [monthTab, setMonthTab] = useState("yesterday");
  const [data, setData] = useState([]);
  const [optionType, setOptionType] = useState([])

  useEffect(() => {
    if(optionTypeEnum){
      let filter=optionTypeEnum.filter((list)=>{return list.value!=allEnum})
      setOptionType(filter)
    }
  }, [optionTypeEnum])
  

  useEffect(() => {
    if (selectMarketing) {
      getDataFun();
    }
  }, [selectMarketing]);

  const getDataFun = async () => {
    try {
      setData([])
      const value = totalAll();
      setMonthTab("total");
      const type = optionType;
      // for (let i = 0; i < type.length; i++) {
        const sendData = {
          A_nOptType: allEnum,
          A_nOptUserType: selectMarketing,
          A_nFromTime: value.from,
          A_nToTime: value.to,
        };
        let result = await getAffiliateDashboard(sendData);
        if (result.data.length > 0) {
          setData(result.data)
          // setData((prevData) => [...prevData, ...result.data]);
          // if (type[i].value == membershipEnum) {
          //   const summary = calculateSummary(result.data);
          //   const finalObject = {
          //     nOptType: summary.nOptType || 2, 
          //     ReportName: "All",
          //     nEntityId: summary.nEntityId || 414, // Assuming nEntityId is constant or derived from the data
          //     UserCount: summary.UserCount || 0,
          //     CommissionUSDT: summary.CommissionUSDT || 0,
          //     CommissionGZUT: summary.CommissionGZUT || 0,
          //   };
          //   let resultData = [finalObject, ...result.data];
          //   setData((prevData) => [...prevData, ...resultData]);
          // } else {
          //   setData((prevData) => [...prevData, ...result.data]);
          // }
        }
      // }
      
    } catch (error) {}
  };

  const getDashboardMarketing = async (value) => {
    const type = optionType;
    setData([]);
    // for (let i = 0; i < type.length; i++) {
      const sendData = {
        A_nOptType: allEnum,
        A_nOptUserType: selectMarketing,
        A_nFromTime: value.from,
        A_nToTime: value.to,
      };
      let result = await getAffiliateDashboard(sendData);
      if (result.data.length > 0) {
        setData(result.data)
        // setData((prevData) => [...prevData, ...result.data]);
        // if (type[i].value == membershipEnum) {
        //   const summary = calculateSummary(result.data);
        //   const finalObject = {
        //     nOptType: summary.nOptType || 2, // Assuming nOptType is constant or derived from the data
        //     ReportName: "All",
        //     nEntityId: summary.nEntityId || 414, // Assuming nEntityId is constant or derived from the data
        //     UserCount: summary.UserCount || 0,
        //     CommissionUSDT: summary.CommissionUSDT || 0,
        //     CommissionGZUT: summary.CommissionGZUT || 0,
        //   };
        //   let resultData = [finalObject, ...result.data];
        //   setData((prevData) => [...prevData, ...resultData]);
        // } else {
        //   setData((prevData) => [...prevData, ...result.data]);
        // }
      // }
    }
  };

  const handleChange = (e) => {
    setSelectMarketing(e.target.value);
  };

  // const calculateSummary = (data) => {
  //   return data.reduce((summary, item) => {
  //     summary.UserCount = (summary.UserCount || 0) + item.UserCount;
  //     summary.CommissionUSDT =
  //       (summary.CommissionUSDT || 0) + item.CommissionUSDT;
  //     summary.CommissionGZUT =
  //       (summary.CommissionGZUT || 0) + item.CommissionGZUT;
  //     return summary;
  //   }, {});
  // };

  return (
    <>
      <Header />
      <div className="man_container d-lg-flex">
        <Sidebar />
        <div className="right_container">
          <div className="container">
            {/* <Banner /> */}
            <div className="w_box  mb-4 p-3">Team Daily New Registration: 0</div>
            <div className="row">
            <div className="col-md-4 col-lg-3">
            <select
              value={selectMarketing}
              onChange={handleChange}
              className="mb-3 form-select form-control"
            >
              {affiliateEnum.map((list) => {
                return (
                  <option value={list.value} key={list.key}>
                    {list.key}
                  </option>
                );
              })}
            </select>
            </div>
            </div>
          
          
            <ul className="nav contatcs-tab mb-4">
              <li className="nav-item">
                <button
                  className={`nav-link ${monthTab === "yesterday" ? "active" : ""}`}
                  onClick={() => {
                    getDashboardMarketing(yesterday());
                    setMonthTab("yesterday");
                  }}
                >
                  Yesterday
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    monthTab === "last-7-days" ? "active" : ""
                  }`}
                  onClick={() => {
                    getDashboardMarketing(last7Days());
                    setMonthTab("last-7-days");
                  }}
                >
                  Last 7 days
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    monthTab === "last-30-days" ? "active" : ""
                  }`}
                  onClick={() => {
                    getDashboardMarketing(last30Days());
                    setMonthTab("last-30-days");
                  }}
                >
                  Last 30 days
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    monthTab === "last-month" ? "active" : ""
                  }`}
                  onClick={() => {
                    getDashboardMarketing(lastMonth());
                    setMonthTab("last-month");
                  }}
                >
                  Last Month
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${monthTab === "this-year" ? "active" : ""}`}
                  onClick={() => {
                    getDashboardMarketing(thisYear());
                    setMonthTab("this-year");
                  }}
                >
                  This Year
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${monthTab === "total" ? "active" : ""}`}
                  onClick={() => {
                    getDashboardMarketing(totalAll());
                    setMonthTab("total");
                  }}
                >
                  Total
                </button>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active " id="home"></div>
              <div className="tab-pane " id="menu1"></div>
              <div className="tab-pane " id="menu2"></div>
            </div>
            {data?.map((list) => {
              return (
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                    <a className="d_box " href="#">
                      <span className="ttt_box_icon ttt_box_icon1">
                        <i className="bi bi-person-check"></i>
                      </span>
                      <div className="d_hadding">
                        {list.ReportName}
                        {/* {list.nOptType === membershipEnum
                          ? `Membership (${list.ReportName})`
                          : list.ReportName} */}
                        <div className="d_value">{list.UserCount}</div>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                    <a className="d_box " href="#">
                      <span className="ttt_box_icon ttt_box_icon1">
                        <i className="bi bi-person-check"></i>
                      </span>
                      <div className="d_hadding">
                        {list.nOptType == membershipEnum
                          ? list.ReportName
                          : `${list.ReportName} Commission`}
                        <div className="d_value">
                          {list.CommissionUSDT} <span>(USDT)</span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                    <a className="d_box " href="#">
                      <span className="ttt_box_icon ttt_box_icon1">
                        <i className="bi bi-person-check"></i>
                      </span>
                      <div className="d_hadding">
                        {list.nOptType == membershipEnum
                          ? list.ReportName
                          : `${list.ReportName} Commission`}
                        <div className="d_value">
                          {list.CommissionGZUT} <span>(GZUT)</span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              );
            })}
            {isLoading && <Loader />}
            <Graphs
              selectMarketing={selectMarketing}
              optionTypeEnum={optionType}
              Loader={Loader}
              totalAll={totalAll}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Dashboard;
