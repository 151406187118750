import React from "react";

function Footer() {
  return (
    <section className="contact  footer" id="contact">
      <div className="copyright p-2 text-center">
        &copy; 2024 3.0 Verse. All Rights Reserved version 0.3
      </div>
    </section>
  );
}

export default Footer;
