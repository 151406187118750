import moment from "moment";
import React, { useEffect, useState } from "react";
import { useGetTopAffiliateMutation } from "../../../redux/affiliateApi";
import {
  allEnum,
  commTypeEnum,
  convertNanoSecond,
  optionTypeEnum,
  registrationEnum,
  USDTEnum,
} from "../../constant/enum";
import { exportToExcel } from "../../constant/exportToExcel";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import Sidebar from "../../widgets/Sidebar";
import Pagination from "../Pagination";

function TopAffilates() {
  const [getTopAffiliate, { data }] = useGetTopAffiliateMutation();
  const [tab, setTab] = useState(registrationEnum);
  const [commType, setCommType] = useState(USDTEnum);
  // const [commTypeName, setCommTypeName] = useState("USDT");
  const [startDate, setStartDate] = useState("0");
  const [filterTopAffilate, setFilterTopAffilate] = useState([])
  const [searchQuery, setSearchQuery] = useState('');
  const [optionType, setOptionType] = useState([])
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    if(optionTypeEnum){
      let filter=optionTypeEnum.filter((list)=>{return list.value!=allEnum})
      setOptionType(filter)
    }
  }, [optionTypeEnum])

  useEffect(() => {
    setTenYearDate();
  }, []);

  useEffect(() => {
    if (tab && commType && startDate && endDate) {
      const sendData = {
        A_nOptType: tab,
        A_nOptCommType: commType,
        A_nFromTime:
          startDate === "0"
            ? startDate
            : convertNanoSecond(new Date(startDate)),
        A_nToTime: convertNanoSecond(new Date(endDate)),
      };
      getTopAffiliate(sendData);
    }
  }, [tab, commType]);

  useEffect(() => {
    if(data){
      setFilterTopAffilate(data)
    }
  }, [data])

  const setTenYearDate = () => {
    const tenYear = new Date();
    tenYear.setMonth(0);
    tenYear.setDate(1);
    tenYear.setFullYear(2010);
    // Format tomorrow's date as needed (e.g., YYYY-MM-DD)
    const tenYearFormatted = tenYear.toISOString().split("T")[0];
    setStartDate(tenYearFormatted);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "startDate") {
      setStartDate(value);
    }
    if (name === "endDate") {
      setEndDate(value);
    }
    if(name==="commType"){
      setCommType(value);
      // let filter=commTypeEnum.filter((list)=>{return list.value==value})
      // setCommTypeName(filter[0].key)
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const sendData = {
      A_nOptType: tab,
      A_nOptCommType: commType,
      A_nFromTime: convertNanoSecond(new Date(startDate)),
      A_nToTime: convertNanoSecond(new Date(endDate)),
    };
    getTopAffiliate(sendData);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = data?.filter(item =>
      Object.values(item).some(value =>
        typeof value === 'string' && value.toLowerCase().includes(query.toLowerCase())
      )
    );
    setFilterTopAffilate(filtered);
  };
  const handleExcel = () => {
    if (filterTopAffilate.length > 0) {
      exportToExcel(
        filterTopAffilate,
        `top_affiliate_${moment(new Date()).format("DD-MM-YYYY,HH:mm:ss")}`
      );
    } else {
      let arr = [
        {
          nOptType: "",
          ReportName: "",
          Date: "",
          EntityId: "",
          FullName: "",
          CommissionUSDT: "",
          CommissionGZUT: "",
          sEmailId: "",
          sMobileNo: "",
        },
      ];
      exportToExcel(
        arr,
        `top_affiliate_${moment(new Date()).format("DD-MM-YYYY,HH:mm:ss")}`
      );
    }
  };
  const totalPages = Math.ceil(filterTopAffilate?.length / itemsPerPage);

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the data for the current page
  const currentPageData = filterTopAffilate?.slice(startIndex, endIndex);


  return (
    <>
      <Header />
      <div className="man_container d-lg-flex">
        <Sidebar />
        <div className="right_container">
          <div className="container">
            <ul className="nav contatcs-tab mb-4">
              {optionType?.map((list) => {
                return (
                  <li
                    className="nav-item"
                    key={list.key}
                    onClick={() => {
                      setTab(list.value);
                    }}
                  >
                    <a
                      className={`nav-link ${
                        tab === list.value ? "active" : ""
                      } `}
                      data-toggle="tab"
                      href="#Registration"
                    >
                      {list.key}
                    </a>
                  </li>
                );
              })}
            </ul>
            <div className="w_box p-md-4 p-3 mb-4">
              <div className="filter_box mb-4">
                <div className="row row8 align-items-center">
                  <div className="col-md-auto  ">Filter:</div>
                  <div className="col-md-3 col-lg-2 col-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by Name"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>
                  <div className="col-md-auto  col-6">
                    <select
                      className="form-control"
                      value={commType}
                      name="commType"
                      onChange={handleChange}
                     
                    >
                      {commTypeEnum.map((list) => {
                        return <option value={list.value}>{list.key}</option>;
                      })}
                    </select>
                  </div>
                  <div className="col-md-3 col-lg-2 col-5">
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Start Date"
                      value={startDate}
                      name="startDate"
                      onChange={handleChange}
                      min="2010-01-01"
                      max={new Date().toISOString().substr(0, 10)}
                    />
                  </div>
                  <div className="col-md-auto col-2 text-center">To</div>

                  <div className="col-md-3 col-lg-2 col-5">
                    <input
                      type="date"
                      value={endDate}
                      className="form-control"
                      placeholder="End date"
                      name="endDate"
                      onChange={handleChange}
                      min={startDate}
                      max={new Date().toISOString().substr(0, 10)}
                    />
                  </div>
                  <div className="col-auto">
                    <button
                      type="button"
                      className="btn "
                      onClick={handleSearch}
                    >
                      Search
                    </button>
                  </div>
                  <div className="col-auto">
                    <button type="button" className="btn " onClick={handleExcel}>
                      Export
                    </button>
                  </div>
                </div>
              </div>
              <h5 className="w600 h55 mb-3">List of Members</h5>
              <div className="tab-content">
                <div className="tab-pane active " id="Registration">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Registration ID</th>
                          <th>GZUT</th>
                          <th>USDT</th>
                          <th>Email ID</th>
                          <th>Mobile No.</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentPageData?.map((list)=>{
                            return (
                              <tr>
                              <td>{list.FullName}</td>
                              <td>{list.EntityId}</td>
                              <td>{list.CommissionGZUT}</td>
                              <td>{list.CommissionUSDT}</td>
                              <td>{list.EmailId}</td>
                              <td>{list.MobileNo}</td>
                            </tr>
                            )
                        })}
                       
                      </tbody>
                    </table>
                  </div>
                </div>
                <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TopAffilates;
