import React from "react";
import Transfer from "./Transfer";


function WithDraw() {

  return (
    <>
      <div className="row mb-4">
        <div className="col-md-6">
          <div className="w_box p-md-4 p-3 mb-4">
            <ul className="nav contatcs-tab mb-4">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#Withdrawal"
                >
                  Withdrawal
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#Transfer">
                  Transfer
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active " id="Withdrawal">
                <h5 className="w600 h55 mb-3">Withdrawal</h5>
                <div className="w_box_border  p-3">
                  <div className="form-group">
                    <label>Available USDT</label>
                    <input className="form-control" type="test" value="500" />
                  </div>
                  <div className="form-group">
                    <label>Quantity</label>
                    <input
                      className="form-control"
                      type="test"
                      placeholder="Enter Quantity"
                      value=""
                    />
                  </div>
                  <button type="submit" className="btn">
                    Confirm
                  </button>
                </div>
              </div>
              <div className="tab-pane " id="Transfer">
                <h5 className="w600 h55 mb-3">Transfer</h5>
                 <Transfer/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WithDraw;
