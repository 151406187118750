import React,{useState,useEffect} from "react";
import { useGetMaterialMutation } from "../../../redux/materialApi";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import Sidebar from "../../widgets/Sidebar";
import {jwtDecode} from "jwt-decode";

function Material() {
  const token=localStorage.getItem('jwtToken')
  const [getMaterial,{data:record}]=useGetMaterialMutation()
  const [pageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [filterMaterial, setFilterMaterial] = useState([])
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if(record){
      setFilterMaterial(record)
    }
  }, [record])

  useEffect(() => {
    if(pageNo ){
      
     getData()
    }
 }, [pageNo])
 
 const getData=()=>{
  const decode=jwtDecode(token)
   const sendData={
     A_sAffiliateMaterialIdJS:"",
     A_nStatus:1,
     A_nPageNo:pageNo,
     A_nPageSize:pageSize,
     entity_id:decode.entityId
   }
   getMaterial(sendData)
 }

 const handleSearchChange = (event) => {
  const query = event.target.value;
  setSearchQuery(query);
  const filtered = record?.filter(item =>
    Object.values(item).some(value =>
      typeof value === 'string' && value.toLowerCase().includes(query.toLowerCase())
    )
  );
  setFilterMaterial(filtered);
};

  return (
    <>
      <Header />
      <div className="man_container d-lg-flex">
        <Sidebar />
        <div className="right_container">
          <div className="container">
            <div class="filter_box mb-4">
              <div class="row row8 align-items-center">
                <div class="col-auto">Filter:</div>
                <div class="col-md-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search by Name"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
            
              </div>
            </div>
            <div class="row">
              {filterMaterial?.map((list)=>{
                     return (
                      <div class="col-lg-6 col-md-6 mb-3">
                      <div class="w_box  p-3">
                        <div class="position-relative">
                          <img src={list.sImageURL?list.sImageURL:"/assets/img/crypto.jpg"} class="img-fluid" alt=""/>
                        </div>
      
                        <h4 class="w600 mt-3"> {list.sTitle}</h4>
                        <p>
                        {list.sDescription}
                        </p>
                        <div class="text-right">
                          <a  class="btn btn-small " href={list.sArticleURL} target="_blank">
                           View
                          </a>
                          <button type="button" class="btn btn-small ml-1">
                            <i class="bi bi-share-fill"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                     )
              })}
            
            </div>
            <div class="d-flex mt-4">
                <ul class="pagination ml-auto">
              <li class="page-item">
                <button class="page-link" aria-label="Previous" disabled={pageNo===1} onClick={()=>{setPageNo(pageNo-1)}}>
                  <span aria-hidden="true">&laquo;</span>
                  {/* <span class="sr-only">Previous</span> */}
                </button>
              </li>
              <li class="page-item">
                <button class="page-link">{pageNo}</button>
              </li>
              <li class="page-item">
                <button class="page-link" aria-label="Next" onClick={()=>{setPageNo(pageNo+1)}} disabled={record?.length!==10}>
                  <span aria-hidden="true">&raquo;</span>
                  {/* <span class="sr-only">Next</span> */}
                </button>
              </li>
            </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Material;
