import { getAffiliateDashboardApi, getAffiliateDashboardLineChartApi } from "../components/constant/Api";
import { myApi } from "./api";

export const dashboardApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getAffiliateDashboard: builder.mutation({
      query: (post) => ({
        url: getAffiliateDashboardApi,
        method: "POST",
        body:post
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
    }),
    getAffiliateDashboardLineChart: builder.mutation({
      query: (post) => ({
        url: getAffiliateDashboardLineChartApi,
        method: "POST",
        body:post
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
    }),
 
  }),
});

export const {useGetAffiliateDashboardMutation,useGetAffiliateDashboardLineChartMutation } = dashboardApi;