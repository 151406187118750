import React from "react";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import Sidebar from "../../widgets/Sidebar";

function Reward() {
  return (
    <>
      <Header />
      <div className="man_container d-lg-flex">
        <Sidebar />
        <div className="right_container">
          <div className="container">
            <div className="d_box p-4 mb-4">
              <h5 className="w600 h55 mb-3">Rewards</h5>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Reward;
