import moment from "moment";
import React, { useEffect, useState } from "react";
import { useGetAffiliateEarningMutation } from "../../../redux/affiliateApi";
import { affiliateEnum, allEnum, convertNanoSecond } from "../../constant/enum";
import Loader from "../../constant/Loader";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import Sidebar from "../../widgets/Sidebar";
import Pagination from "../Pagination";

function MyEarning() {
  const [getAffiliateEarning, { data, isLoading }] =
    useGetAffiliateEarningMutation();
  const [selectMarketing, setSelectMarketing] = useState("1");
  const [startDate, setStartDate] = useState("0");
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    setTenYearDate();
  }, []);

  const setTenYearDate = () => {
    const tenYear = new Date();
    tenYear.setMonth(0);
    tenYear.setDate(1);
    tenYear.setFullYear(2010);
    const tenYearFormatted = tenYear.toISOString().split("T")[0];
    setStartDate(tenYearFormatted);
  };

  useEffect(() => {
    if (selectMarketing && startDate && endDate) {
      const sendData = {
        A_nOptType: allEnum,
        A_nOptUserType: selectMarketing,
        A_nFromTime:
          startDate === "0"
            ? startDate
            : convertNanoSecond(new Date(startDate)),
        A_nToTime: convertNanoSecond(new Date(endDate)),
      };
      getAffiliateEarning(sendData);
    }
  }, [selectMarketing]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "startDate") {
      setStartDate(value);
    }
    if (name === "endDate") {
      setEndDate(value);
    }
    if(name==="selectMarketing"){
      setSelectMarketing(value)
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const sendData = {
      A_nOptType: allEnum,
      A_nOptUserType: selectMarketing,
      A_nFromTime: convertNanoSecond(new Date(startDate)),
      A_nToTime: convertNanoSecond(new Date(endDate)),
    };
    getAffiliateEarning(sendData);
  };

  const totalPages = Math.ceil(data && data[1]?.length / itemsPerPage);

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the data for the current page
  const currentPageData = data && data[1]?.slice(startIndex, endIndex);

  return (
    <>
      <Header />
      <div className="man_container d-lg-flex">
        <Sidebar />
        <div className="right_container">
          <div className="container">
            <div className="w_box p-md-4 p-3 mb-4">
              <div className="filter_box mb-4">
                <div className="row row8 align-items-center">
                  <div className="col-md-auto">Filter:</div>
                  <div className="col-md-auto  col-12">
                    <select
                      value={selectMarketing}
                      onChange={handleChange}
                      className="form-select form-control"
                      name="selectMarketing"
                    >
                      {affiliateEnum.map((list) => {
                        return (
                          <option value={list.value} key={list.key}>
                            {list.key}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-3 col-lg-3 col-5 ">
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Start Date"
                      value={startDate}
                      name="startDate"
                      onChange={handleChange}
                      min="2010-01-01"
                      max={new Date().toISOString().substr(0, 10)}
                    />
                  </div>
                  <div className="col-md-auto col-2 text-center">To</div>
                  <div className="col-md-3 col-lg-3 col-5">
                    <input
                      type="date"
                      className="form-control"
                      placeholder="End date"
                      value={endDate}
                      name="endDate"
                      onChange={handleChange}
                      min={startDate}
                      max={new Date().toISOString().substr(0, 10)}
                    />
                  </div>
                  <div className="col-md-auto text-right">
                    <button
                      type="button"
                      className="btn "
                      onClick={handleSearch}
                    >
                      <i className="bi bi-search"></i>
                    </button>
                  </div>
                
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 mb-4">
                  <div className="w_box_border  p-3">
                    <p className="mb-2">USDT Earned</p>
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <h4 className="w600 mb-0">
                        {data && parseFloat(data[0][0]?.CommissionUSDT?data[0][0]?.CommissionUSDT:0).toFixed(2)}
                      </h4>
                    )}
                  </div>
                </div>
                <div className="col-md-3 mb-4">
                  <div className="w_box_border  p-3">
                    <p className="mb-2">GZUT Earned</p>
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <h4 className="w600 mb-0">
                        {data && (data[0][0]?.CommissionGZUT?data[0][0]?.CommissionGZUT:0).toFixed(2)}
                      </h4>
                    )}
                  </div>
                </div>
              </div>
              <h5 className="w600 h55 mb-3">My Earnings</h5>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Affliate Name</th>
                      <th>Affliate Id</th>

                      <th>Amount (USDT/GZUT)</th>
                      <th>Revenue Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && currentPageData?.map((list,index)=>{
                      return (
                        <tr key={index}>
                        <td>{moment(list.Date).format('YYYY-MM-DD')}</td>
                        <td>{list.UserName}</td>
                        <td>{list.EntityId}</td>
                        <td>{list.CommissionUSDT+" / "+ list.CommissionGZUT}</td>
                        <td>{list.ReportName}</td>
                      </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default MyEarning;
