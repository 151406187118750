import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import {callBackBaseUrl } from "../constant/BaseUrl";
import { logIn } from "../services/Login";
import { toast } from "react-toastify";

function Login() {
  const location = useLocation();

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    const ssoToken = params.ssoToken;
    const uid = params.uid;
    const base64String = params.click;
    if (params.ssoToken === null) {
      window.location = callBackBaseUrl + "?callBack=" + window.location;
    } else {
      const userData = {
        uid: uid,
        ssoToken: ssoToken,
      };
      if (uid) {
        checkLoginUser(userData);
        if (base64String) {
          const decodedString = atob(base64String);
          localStorage.setItem("String", decodedString);
        } else {
          localStorage.setItem("String","");
        }
      }
    }
  }, [location]);

  const checkLoginUser = async (userData) => {
    const decoded = jwtDecode(userData.ssoToken);
    const data = { userData: userData, decoded: decoded };
    const result = await logIn(data);
    if (result.status) {
        let token = result.authtoken;
        localStorage.setItem("jwtToken", token);
        localStorage.setItem("userType", result.type);
        toast.success(result.message);
        setTimeout(function () {
            window.location.href = "/dashboard";
        }, 1000);
        return false;
      } else {
        toast.dismiss();
        toast.error(result.message);
        return;
      }
  };

  return (
    <div>
      <div class="text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
}

export default Login;
